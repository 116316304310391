import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Pane, Text, CDialog, Loading } from "shared";
import { getDate, getDay } from "utils/time";
import { Attending, NotAttending } from "./ActivityStatus";
import usePatient from "../../queries/usePatient";
import { FontSize } from "theme";
import useActivities from "../../queries/useActivities";
import BrowseActivities from "./BrowseActivities";
import EditActivity from "./EditActivity";
import { css } from "@emotion/css";
import { definitions } from "api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveActivity from "./RemoveActivity";

type Activity = definitions["activity"];

export const EDITABLE_TYPES = {
  health: ["w1_health_report", "weekly_health_report", "followup_health_report", "functionality_1"],
  functionality: ["functionality_1"],
};

const allTypes = [...EDITABLE_TYPES.functionality, ...EDITABLE_TYPES.health];

// required activities that can be removed
const REMOVABLE_TYPES = [
  "attestation_form",
  "subscription_1",
  "payment_details",
  "physical_examination",
  "self_care_activation",
];

export const isEditableActivity = (activityType: string): boolean => allTypes.includes(activityType);
export const isRemovableActivity = (activityType: string): boolean => REMOVABLE_TYPES.includes(activityType);

export const activityValue = (activity: Activity) => {
  let value;

  if (EDITABLE_TYPES.functionality.includes(activity.type)) {
    value = activity.functionality?.repetitions;
  }
  if (EDITABLE_TYPES.health.includes(activity.type)) {
    value = activity.specific_joint_pain;
  }

  return value?.toString() ?? "";
};

const isCourseWeek = (week?: number | null): week is number => Number.isInteger(week);

const Activities: React.VFC = () => {
  const [expanded, setExpanded] = useState(false);
  const expand = () => setExpanded(true);
  const collapse = () => setExpanded(false);
  const { t } = useTranslation();
  const currentCourseWeek = usePatient().data?.current_course_week?.course_week_number;
  const { data: activitiesData, isSuccess, isLoading } = useActivities(currentCourseWeek);
  const [editActivity, setEditActivity] = useState<Activity | null>(null);

  if (!isCourseWeek(currentCourseWeek)) {
    return (
      <Pane>
        <Text disabled>{t`patients.no_activities`}</Text>
      </Pane>
    );
  }

  const mainEm = activitiesData?.current && (
    <>
      <Pane dark py={1}>
        <Text paragraph bold center mb={0}>
          {t`patients.week`} {currentCourseWeek}
        </Text>
      </Pane>
      {activitiesData.current.map(activity => (
        <Accordion key={activity.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container className={styles.item}>
              <Grid item xs={3}>
                <Text>{getDate(activity.start_date)}</Text>
              </Grid>
              <Grid item xs={1}>
                <Text>{getDay(activity.start_date, true)}</Text>
              </Grid>
              <Grid item xs={4} className={styles.overflow}>
                <Text>
                  {
                    // @ts-ignore
                    t(`activities.${activity.type}`)
                  }
                </Text>
              </Grid>
              <Grid item xs={1}>
                {activity.completed ? <Attending /> : <NotAttending />}
              </Grid>
              <Grid item xs={1}>
                <Text className={styles.centerText}>{activityValue(activity)}</Text>
              </Grid>
              <Grid item xs={1}>
                {isEditableActivity(activity.type) ? (
                  <Button
                    onClick={event => {
                      event.stopPropagation();
                      setEditActivity(activity);
                    }}
                    aria-label="Edit activity"
                  >
                    {t`buttons.edit`}
                  </Button>
                ) : (
                  isRemovableActivity(activity.type) && <RemoveActivity activity={activity} />
                )}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <pre className={styles.json}>{JSON.stringify(activity, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>
      ))}
      <CDialog maxWidth="md" fullWidth open={expanded} onClose={collapse} aria-label="Activity browser">
        <BrowseActivities />
      </CDialog>
      <EditActivity
        activity={editActivity}
        onClose={() => {
          setEditActivity(null);
        }}
      />
      <Box mt={2} mb={1} textAlign="center">
        <Button color="primary" onClick={expand} aria-label="Browse activities">
          {t`patients.view_all`}
        </Button>
      </Box>
    </>
  );

  return (
    <div>
      <Text paragraph caption secondary mt={1}>
        {t`patients.activities`}
      </Text>
      <Pane data-testid="current-activities">
        {isSuccess && mainEm}
        {isLoading && <Loading />}
      </Pane>
    </div>
  );
};

export default Activities;

const styles = {
  item: css`
    align-items: center;
    font-size: ${FontSize.xxs}px;
    white-space: nowrap;
    min-height: 42px;
  `,
  centerText: css`
    text-align: center;
    display: block;
  `,
  overflow: css`
    text-wrap: wrap;
    word-wrap: break-word;
  `,
  json: css`
    font-size: 11px;
  `,
};
