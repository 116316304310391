import { useErrorContext } from "shared";
import { definitions } from "api";
import ConfirmDialog from "shared/ConfirmDialog";
import { Button } from "@material-ui/core";
import { useId } from "utils/hooks";
import useDeleteActivity from "routes/patient/queries/useDeleteActivity";
import { useQueryClient } from "react-query";
import { queryKeys } from "utils/misc";
import { useTranslation } from "react-i18next";

type Activity = definitions["activity"];

interface Props {
  activity: Activity;
}

const RemoveActivity: React.VFC<Props> = ({ activity }) => {
  const id = useId();
  const { mutateAsync: deleteActivity } = useDeleteActivity();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const setErrors = useErrorContext();

  const onRemove = async () => {
    try {
      await deleteActivity(activity.id);
    } catch (error) {
      setErrors(t`errors.could_not_remove_activity`, error);
    } finally {
      queryClient.invalidateQueries(queryKeys.activities(id, activity.course_week_number!));
    }
  };

  return (
    <ConfirmDialog
      type="warning"
      title={t("patient.remove_item_title", { name: activity.type })}
      confirmText={t`buttons.remove`}
      onConfirm={() => onRemove()}
      OpenButton={props => <Button {...props}>{t`buttons.remove`}</Button>}
    />
  );
};

export default RemoveActivity;
