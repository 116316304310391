import { Dialog, DialogActions, IconButton, Button, ButtonProps, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Loading } from "shared";
import { Colors } from "theme";

interface ConfirmDialogProps {
  type: "warning" | "info";
  title: string;
  confirmText: string;
  onConfirm: () => Promise<void>;
  OpenButton: ComponentType<ButtonProps>;
}

const ConfirmDialog: React.VFC<ConfirmDialogProps> = ({
  type = "warning",
  title,
  confirmText,
  onConfirm,
  OpenButton,
}) => {
  const [open, setOpen] = React.useState(false);
  const [isRequesting, setIsRequesting] = React.useState(false);
  const { t } = useTranslation();

  const onOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const onClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(false);
  };
  return (
    <>
      <OpenButton onClick={onOpen} style={type === "warning" ? { color: Colors.ERROR_LIGHT } : undefined} />
      <Dialog disableScrollLock open={open} maxWidth="xs" fullWidth>
        {isRequesting ? (
          <Loading m={10} />
        ) : (
          <>
            <DialogActions>
              <IconButton onClick={onClose} aria-label="Close dialog">
                <Icon secondary of={Close} />
              </IconButton>
            </DialogActions>
            <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
            <DialogActions>
              <Button onClick={onClose}>{t`buttons.cancel`}</Button>
              <Button
                onClick={async event => {
                  event.stopPropagation();
                  setIsRequesting(true);
                  await onConfirm();
                  onClose(event);
                  setIsRequesting(false);
                }}
                style={type === "warning" ? { color: Colors.ERROR_LIGHT } : undefined}
              >
                {confirmText}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
