import { Box, Button, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "shared";
import { getDate, getDateAndTime, getDay } from "utils/time";
import { Attending, NotAttending } from "../ActivityStatus";
import styled from "styled-components";
import { SPACING } from "theme";
import useActivities from "../../../queries/useActivities";
import { activityValue, isEditableActivity, isRemovableActivity } from "../Activities";
import EditActivity from "../EditActivity";
import BrowserHeader from "./BrowserHeader";
import usePatient from "routes/patient/queries/usePatient";
import { definitions } from "api";
import RemoveActivity from "../RemoveActivity";

type Activity = definitions["activity"];

const BrowseActivities: React.VFC = () => {
  const currentCourseWeek = usePatient().data?.current_course_week?.course_week_number;
  const [week, setWeek] = useState(currentCourseWeek);
  const { data: activitiesData, isFetching } = useActivities(week);
  const [editActivity, setEditActivity] = useState<Activity | null>(null);
  const { t } = useTranslation();
  if (week === undefined) return null;

  return (
    <>
      <div>
        <BrowserHeader week={week} onWeekChange={setWeek} />
        <ScrollArea>
          <BrowseDetails>
            <Text bold>{t`form.date`}</Text>
            <Text bold>{t`form.weekday`}</Text>
            <Text bold>{t`form.type`}</Text>
            <Text bold center>
              {t`patients.protocol.completed`}
            </Text>
            <Text bold>{t`form.completed_date`}</Text>
            <Text bold>{t`form.value`}</Text>
            <Text bold center>
              {t`patients.actions`}
            </Text>
            {activitiesData?.current?.map(activity => (
              <React.Fragment key={activity.id}>
                <Text>{getDate(activity.start_date)}</Text>
                <Text>{getDay(activity.start_date, true)}</Text>
                <Text>
                  {
                    // @ts-ignore
                    t(`activities.${activity.type}`)
                  }
                </Text>
                {activity.completed ? <Attending /> : <NotAttending />}
                <Text>{activity.completed_on && getDateAndTime(activity.completed_on)}</Text>
                <Text>{activityValue(activity)}</Text>
                <Box minHeight="42px">
                  {isEditableActivity(activity.type) ? (
                    <Button
                      onClick={event => {
                        event.stopPropagation();
                        setEditActivity(activity);
                      }}
                      aria-label="Edit activity"
                    >
                      {t`buttons.edit`}
                    </Button>
                  ) : (
                    isRemovableActivity(activity.type) && <RemoveActivity activity={activity} />
                  )}
                </Box>
              </React.Fragment>
            ))}
            {isFetching && (
              <Overlay>
                <CircularProgress size={64} />
              </Overlay>
            )}
          </BrowseDetails>
        </ScrollArea>
      </div>
      <EditActivity
        activity={editActivity}
        onClose={() => {
          setEditActivity(null);
        }}
      />
    </>
  );
};

export default BrowseActivities;

const ScrollArea = styled.div`
  position: relative;
  height: 80vh;
  overflow: auto;
`;

const BrowseDetails = styled.div`
  display: grid;
  align-items: center;
  font-size: small;
  grid-row-gap: ${SPACING}px;
  grid-column-gap: ${SPACING}px;
  justify-content: space-evenly;
  grid-template-columns: repeat(7, auto);
  gap: ${SPACING}px;
  margin: ${2 * SPACING}px;
  white-space: nowrap;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
`;
