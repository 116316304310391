import { useTranslation } from "react-i18next";
import { Page, Text, View, Document, Link } from "@react-pdf/renderer";
import { format, parseISO } from "date-fns";
import { Footer, PageHeader, PatientDetails, Table } from "./index";
import { styles } from "../styles";

interface Props {
  patient: {
    first_name: string;
    last_name: string;
    birthday: string;
    address: string;
    phone_number: string;
    nhs_number: string;
    email: string;
    discharge_email_consent: boolean;
    therapist: {
      first_name: string;
      last_name: string;
    };
  };
  kickOffNote: any;
  dischargeNote: any;
  ukDischargeLetter: any;
  initialEQ5D: string;
  latestEQ5D: string;
  gpAddress: string;
  hasAnsweredFollowUpHealthReport: boolean;
}

export const DischargeLetter: React.VFC<Props> = ({
  patient: {
    first_name,
    last_name,
    birthday,
    address,
    phone_number,
    nhs_number,
    email,
    discharge_email_consent,
    therapist: { first_name: therapistFirstName, last_name: therapistLastName },
  },
  kickOffNote,
  dischargeNote,
  ukDischargeLetter,
  initialEQ5D,
  latestEQ5D,
  gpAddress,
  hasAnsweredFollowUpHealthReport,
}) => {
  const { t } = useTranslation();

  const { bmi, plan: kickOffPlan } = kickOffNote?.medical_record_note || {};

  const {
    adherence_measure,
    discharge_note_to_patient,
    discharge_further_action,
    discharge_urgency,
    goal_free_text,
    goal_free_text_achieved,
    goal_functionality,
    goal_functionality_achieved,
    goal_pain,
    goal_pain_achieved,
    medication,
    past_medical_history,
    plan,
    status,
    subjective,
  } = dischargeNote?.medical_record_note || {};

  const merged = { ...kickOffNote, ...dischargeNote };
  const differentialDiagnosis = merged?.medical_record_note?.differential_diagnosis;
  const signedAt = merged?.signed_at;
  const patientName = `${first_name} ${last_name}`;

  const getDischargeUrgencyTranslation = (urgency: string) => {
    return urgency === "routine"
      ? t("patients.uk_discharge_letter.discharge_urgency.routine")
      : t("patients.uk_discharge_letter.discharge_urgency.urgent");
  };

  const getDischargeFurtherActionTranslation = (dischargeFurtherAction: boolean) => {
    return dischargeFurtherAction ? t("patients.uk_discharge_letter.discharge_further_action") : "";
  };

  const getGoalAchievedTranslation = (achieved: boolean) => {
    return achieved
      ? t("patients.uk_discharge_letter.goal_achieved")
      : t("patients.uk_discharge_letter.goal_not_achieved");
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PageHeader />

        <View style={{ alignItems: "flex-end", marginTop: "100px" }}>
          <View style={styles.row}>
            <Text style={styles.bold}>{t("patients.uk_discharge_letter.contact_at")}</Text>
            <Link src={`mailto:${t("patients.uk_discharge_letter.contact_link")}`}>
              {t("patients.uk_discharge_letter.contact_link")}
            </Link>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>{t("patients.uk_discharge_letter.date")}</Text>
            <Text>{signedAt && format(parseISO(signedAt), "d/M/yyyy")}</Text>
          </View>
        </View>

        <PatientDetails
          full_name={patientName}
          birthday={birthday}
          address={address.split("\n").join(", ")}
          phone_number={phone_number}
          nhs_number={nhs_number}
          differential_diagnosis={differentialDiagnosis}
        />

        <View style={styles.section}>
          <Text style={styles.bold}>{t("patients.uk_discharge_letter.plan")}</Text>
          <Text style={discharge_urgency === "urgent" ? { ...styles.underline } : {}}>
            {getDischargeUrgencyTranslation(discharge_urgency)}
          </Text>
          <Text>{getDischargeFurtherActionTranslation(discharge_further_action)}</Text>
          <Text>{plan}</Text>
        </View>

        {subjective && (
          <View style={styles.section}>
            <Text style={styles.bold}>{t("patients.uk_discharge_letter.subjective")}</Text>
            <Text>{subjective}</Text>
          </View>
        )}

        {past_medical_history && (
          <View style={styles.section}>
            <Text style={styles.bold}>{t("patients.uk_discharge_letter.past_medical_history")}</Text>
            <Text>{past_medical_history}</Text>
          </View>
        )}

        {medication && (
          <View style={styles.section}>
            <Text style={styles.bold}>{t("patients.uk_discharge_letter.medication")}</Text>
            <Text>{medication}</Text>
          </View>
        )}

        {kickOffPlan && (
          <View style={styles.section}>
            <Text style={styles.bold}>{t("patients.uk_discharge_letter.treatment_programme")}</Text>
            <Text>{kickOffPlan?.replace("Insert any other specific advice.", "")}</Text>
          </View>
        )}

        <View style={styles.section}>
          <Text style={styles.bold}>{t("patients.uk_discharge_letter.progression")}</Text>
          <Table
            initialPainRating={ukDischargeLetter?.initial_pain}
            latestPainRating={ukDischargeLetter?.pain_rating}
            initialFunctionScore={ukDischargeLetter?.initial_functionality}
            latestFunctionScore={ukDischargeLetter?.functionality}
            healthformScoreType={ukDischargeLetter?.healthform_score_type || ""}
            initialHealthformScore={ukDischargeLetter?.initial_healthform_score || ""}
            healthformScore={ukDischargeLetter?.healthform_score || ""}
            initialEQ5D={initialEQ5D}
            latestEQ5D={latestEQ5D}
            adherence={adherence_measure}
            bmi={bmi}
            hasAnsweredFollowUpHealthReport={hasAnsweredFollowUpHealthReport}
          />
        </View>

        {status && (
          <View style={styles.section} wrap={false}>
            <Text style={styles.bold}>{t("patients.uk_discharge_letter.management")}</Text>
            <Text>{status}</Text>
          </View>
        )}

        <View style={styles.section} wrap={false}>
          <Text style={styles.bold}>{t("patients.uk_discharge_letter.goals")}</Text>
          {goal_pain && (
            <View style={styles.row}>
              <Text>{t("patients.uk_discharge_letter.goal_pain")}</Text>
              <Text>{`${goal_pain}. `}</Text>
              <Text>{getGoalAchievedTranslation(goal_pain_achieved)}</Text>
            </View>
          )}
          {goal_functionality && (
            <View style={styles.row}>
              <Text>{t("patients.uk_discharge_letter.goal_functionality")}</Text>
              <Text>{`${goal_functionality}. `}</Text>
              <Text>{getGoalAchievedTranslation(goal_functionality_achieved)}</Text>
            </View>
          )}
          {goal_free_text && (
            <View style={styles.row}>
              <Text>
                <Text>{t("patients.uk_discharge_letter.goal_free_text")}</Text>
                <Text>{`${goal_free_text}. `}</Text>
                <Text>{getGoalAchievedTranslation(goal_free_text_achieved)}</Text>
              </Text>
            </View>
          )}
        </View>

        <View style={styles.section}>
          <Text>{discharge_note_to_patient}</Text>
        </View>

        <Footer
          dischargeEmailConsent={discharge_email_consent}
          gpAddress={gpAddress}
          patientName={patientName}
          patientAddress={address}
          patientEmail={email}
          therapistFirstName={therapistFirstName}
          therapistLastName={therapistLastName}
        />
      </Page>
    </Document>
  );
};
