import api from "api";
import { useMutation } from "react-query";
import { useId } from "utils/hooks";

const useDeleteActivity = () => {
  const patientId = useId();
  return useMutation((id: number) => api("DELETE /patients/{patient_id}/activities/{id}", [patientId, id]));
};

export default useDeleteActivity;
